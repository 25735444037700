<template>
	<div>
		<nav class="navbar navbar-expand-lg navbar-inverse main-nav fixed-top">
			<div class="sticky-nav-activate fixed-top"></div>
			<div class="container" style="display: flex; justify-content: space-around;">
				<div class="logo">
					<a class="navbar-brand" href="/"><img src="/static/logo.png" alt=""></a>
					<!-- <a class="navbar-brand sticky-logo" href="/"><img src="static/picture/logo.png" alt=""></a> -->
				</div>

				<span class="visible-xs-block cx-nav" style="position: relative; left: auto;">
					<router-link class="nav-link" to="/">{{$t('trade')}}</router-link>
					<router-link class="nav-link" to="/pool">{{$t('Mine')}}</router-link>
					<a class="nav-link" v-if="$i18n.locale=='en'" @click="lang('tc')">中文</a>
					<a class="nav-link" v-if="$i18n.locale=='tc'" @click="lang('en')">English</a>
				</span>
				<!-- 右侧菜单 -->
				<navbar></navbar>
			</div>
		</nav>
		<!-- 注册弹窗 -->
		<el-dialog v-loading="regDialogLoading" element-loading-text="loading" title="请先加入LP分紅" width="350px"
			:visible.sync="regDialogVisible" :show-close="false" :before-close="handleClose" class="regForm">
			<p>Myaccount/我的账户：<br><span style="font-size: 12px;">{{$store.state.address}}</span></p>
			<p v-if="invitecode"> Inviter/邀请者账户：<br><span style="font-size: 12px;">{{invitecode}}</span></p>
			<div style="margin-top: 40px; display: flex; justify-content: space-between;">
				<el-button type="primary" size="medium" style="width: 40%;" @click="setReferrer()">加入LP分紅</el-button>
				<el-button type="primary" size="medium" style="width: 40%;" @click="later()">稍后加入</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Web3 from "web3";
	import {
		ethers
	} from 'ethers';
	// var vConsole = new VConsole(); 
	const Web3Modal = window.Web3Modal.default;
	const WalletConnectProvider = window.WalletConnectProvider.default;
	let web3Modal
	let provider;
	import contract_address from '@/common/config/contractAddress.js';
	import MiningPool from '@/common/config/MiningPool.json';
	import Token from '@/common/config/ERC20.json';
	let MiningPoolContract;
	import * as state from '@/store/contract.js'

	import {
		useContract
	} from '@/common/api/core.js'
	import navbar from './navbar.vue'
	export default {
		components: {
			navbar
		},
		data() {
			return {
				// PUMPAddress: PUMPAddress,
				// USDTaddress: contract_address.USDT,
				web3Modal: '',
				payoutDialogVisible: false,
				stakeDialogVisible: false,
				cancleDialogVisible: false,
				invitecode: '', //邀请编码
				regDialogVisible: false, //是否显示绑定界面
				regDialogLoading: false,
				show: false,
			};
		},
		filters: {
			filter_address(value) {
				var len = value.length;
				var x = value.substring(10, len - 9);
				var values = value.replace(x, '****');
				return values;
			},
			filtersUnits(num) {
				if (!num) return 0;
				return ethers.utils.formatUnits(ethers.BigNumber.from(num));
			}
		},
		async created() {
			//邀请链接
			let code = this.$route.query.code;
			if (code) {
				this.invitecode = code;
				localStorage.setItem('invitecode', code);
			} else {
				this.invitecode = localStorage.getItem('invitecode');
			}

			// console.log(this.$i18n.locale);
			//钱包模型
			const providerOptions = {
				walletconnect: {
					package: WalletConnectProvider, // required
					options: {
						infuraId: '7c0b90d07c5448beb91cecac47d7b33e' // required
					}
				}
			};
			this.web3Modal = new Web3Modal({
				// network: 'Rinkeby', // optional
				network: 'mainnet', // optional
				cacheProvider: true, // optional
				providerOptions // required
			});
			//缓存自动链接  
			await this.onConnect();
			// console.log('已经链接钱包');
		},
		mounted() { //页面终止执行
			//链接钱包
			console.log(this.$i18n.locale, '8888888888');

		},
		methods: {
			getUrlParam(key) {
				var href = window.location.hash;
				// console.log('路由', window.location.hash);

				// console.log(111111111111,href);
				var url = href.split("?");
				if (url.length <= 1) {
					return "";
				}
				// console.log('7777777',url);
				var params = url[1].split("&");
				for (var i = 0; i < params.length; i++) {
					var param = params[i].split("=");

					if (key == param[0]) {
						return param[1];
					}
				}
			},
			later() { //之后绑定，注销邀请码
				localStorage.removeItem("invitecode");
				this.regDialogVisible = false;
			},
			//绑定关系
			async bindReferrer() {
				let code = this.getUrlParam('code');
				// console.log('aaaaaaaa', code);
				if (code) {
					this.invitecode = code;
					localStorage.setItem('invitecode', code);
				} else {
					this.invitecode = localStorage.getItem('invitecode');
				}
				let address = this.$store.state.address;
				// let count = await MiningPoolContract.referreeCount(address);
				// //判断邀请链接是否具备邀请资格
				// if (this.invitecode) {
				// 	let is_invite = await MiningPoolContract.canBeReferer(this.invitecode);
				// 	if (!is_invite) {
				// 		this.$message.error('该账户不具备邀请资格');
				// 		return false;
				// 	}
				// }
				if (address && address != this.invitecode && this.invitecode) {
					MiningPoolContract.hasReferer(address).then(res => {
						console.log(res);
						if (!res) {
							this.regDialogVisible = true;
						}
					})
				}
			},
			//绑定邀请关系
			async setReferrer() {
				this.regDialogLoading = true; //显示状态 
				let trade = await MiningPoolContract.setReferer(this.invitecode);
				this.$message.success('请等待链上确认');
				this.regDialogVisible = false;
				this.regDialogLoading = false;
			},
			//绑定邀请关系
			// bind_relate
			lang(l) { //切换语言
				this.$i18n.locale = l;
			},

			handleClose() { //关闭邀请界面
				this.regDialogVisible = true;
			},
			async onConnect() {
				//链接钱包
				provider = await this.web3Modal.connect();

				state.contract.initializedContracts = true; //标记钱包已经链接
				//监听区块高度 
				const web3 = new Web3(provider);
				const accounts = await web3.eth.getAccounts();
				const address = accounts[0];
				localStorage.setItem('address', address);

				state.contract.default_account = address;
				await this.$store.commit('setState', {
					web3,
					provider,
					connected: true,
					address
				});
				let provider2 = new ethers.providers.Web3Provider(provider);
				const wallet = provider2.getSigner();
				MiningPoolContract = useContract('Refer');
				this.bindReferrer(); //绑定关系 
			},
		}


	}
</script>
<style lang="scss" scoped="scoped">
	.cx-nav {
		a {
			float: left;
			display: inline-block;
			color: #fff;
		}
	}
</style>
