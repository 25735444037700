import deployments from '@/common/deployments.json';
import {
	getWallet,
	Contract
} from '@/common/ethersConnect'

export function useContract(contact) {
	return new Contract(deployments[contact].address, deployments[contact].abi, getWallet());
}
/**
 *添加流动性
 */
export function add_url() {
	let baseUrl = 'https://ht.mdex.me/';
	baseUrl = 'https://quickswap.exchange/';
	baseUrl = 'https://app.kswap.finance/';
	baseUrl = 'https://pancakeswap.finance/';

	return baseUrl + 'add/' + deployments['USDT'].address + '/' + deployments['Token'].address;
}

export function getRemoveLink() {
	let baseUrl = 'https://ht.mdex.me/';
	baseUrl = 'https://quickswap.exchange/';
	baseUrl = 'https://app.kswap.finance/';
	baseUrl = 'https://pancakeswap.finance/';

	return baseUrl + 'remove/' + deployments['USDT'].address + '/' + deployments['Token'].address;
}

export function swap_url() {
	let baseUrl = 'https://ht.mdex.me/';
	baseUrl = 'https://quickswap.exchange/';
	baseUrl = 'https://app.kswap.finance/';
	return baseUrl + '#/swap/?inputCurrency=' + deployments['USDT'].address + '&outputCurrency=' + deployments['Token']
		.address;
}
