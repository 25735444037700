import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
//应用类型: 数字货币项目方，区块浏览器很难做为单独的项目，钱包项目，交易所项目
//一组路由
const routes = [ 
	{
		path:'/',
		component: () => import('@/views/index/index') //钱包
	}, 
	{
		path:'/pool',
		component: () => import('@/views/pool/index') //钱包
	},
	{
		path:'/contact',
		component: () => import('@/views/account/contact') //钱包
	},
	{
		path: '/invite',
		component: () => import('@/views/account/invite')
	},
	{
		path: '/about',
		component: () => import('@/views/account/about')
	}
]
//路由管理
const router = new VueRouter({
	// mode: 'history', //去掉url中的#
	routes
})
export default router
