<template>
	<div class="wrap">
		<Header />
		<router-view />
	</div>
</template>
<script>
	import Header from '@/components/Header.vue';
	export default {
		components: {
			Header
		}
	};
</script>
<style lang="scss">
	@import "/src/uni.scss";

	body { 
		background: #2196f3; 
		background: linear-gradient(45deg, #871f75, #110628); 
		min-height: 100%;
		font-family: 'Varela Round', sans-serif !important;
	}

	.navbar-inverse .navbar-nav .nav-link,
	.navbar-inverse .navbar-nav .nav-link:focus {
		color: #000
	}

	.el-card,
	.el-message {
		border-radius: 30px !important;
	}

	.el-card__body {
		padding: 40px !important;
	}

	.wrap {
		padding: 15px;
	}

	.navbar-expand-lg .navbar-nav .nav-link {
		padding: 15px 15px;
	}

	.navbar-nav {
		i {
			margin-right: 10px;
		}
	}

	

	// .navbar-expand-lg.navbar-inverse.main-nav .navbar-toggler.collapsed .icon-bar {
	// 	background-color: #030303 !important;
	// }

	// 电脑端样式
	@media (min-width: 1200px) {
		.navbar-expand-lg .navbar-nav .nav-link {
			padding: 35px 15px;
		}

		.container {
			max-width: 1400px;
		}

		
	}

	// 手机端样式
	@media only screen and (max-width: 991px) {
		.navbar-expand-lg .navbar-collapse {
			padding-top: 50px;
		}

		.navbar-expand-lg .dropdown-toggle::after {
			font-size: 1.1rem;
		}

		.mobile {
			display: none;
		}
	}

	.btn-primary-gradient,
	.btn-primary-gradient:focus,
	.btn-primary.active {
		background: linear-gradient(120deg, #71d5a1 0%, #4d2712 100%) !important;
		-webkit-box-shadow: none;
		box-shadow: none !important;
	}
</style>
