import Vue from "vue";
//初始化状态
let initState = () => ({
	totalSupply: 0, //总供给量
	balanceOf: 0, //账户余额
})
const state = Vue.observable({
	default_account: '', //默认账户
	initializedContracts: false,
	contracts: { //合约
		usdt: {
			currentContract: 'USDT',
			...initState(),
		},
		lp: {
			currentContract: 'LPToken',
			...initState(),
		}
	}
});
export const getters = {
	default_account: () => state.default_account || '0x0000000000000000000000000000000000000000',
	initializedContracts: () => state.initializedContracts,
}

export let contract = state;
