import Vue from 'vue'
import Vuex from 'vuex' 
Vue.use(Vuex) 
export default new Vuex.Store({
	state: {
		address: "",
		// web3: null,
		provider: null,
		// connected: false,
		// chainId: 1,
		// networkId: 1
	},
	mutations: {
		setState(state, opt) {
			for (let [key, val] of Object.entries(opt)) {
				state[key] = val;
			}
		}
	},
	actions: {},
	modules: {}
})
