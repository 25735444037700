import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VConsole from 'vconsole';
import './common/axios'
import ElementUI from 'element-ui'
import CxUI from '@/uni_modules/cxyz-web3';
import '@/assets/element-ui/index.css'
// import '@/uni.scss';
//CSS3动画库
// import animated from "animate.css";
import Clipboard from 'v-clipboard'
Vue.use(Clipboard)
// const vConsole = new VConsole();


import i18n from '@/i18n/index.js';



Vue.prototype.baseCoin = "TIGER";

// Vue.use(animated);
Vue.config.productionTip = true //错误提示
Vue.use(ElementUI) //第三方组件
Vue.use(CxUI) //自定义UI组件
//创建实例
new Vue({
	router,
	i18n,
	store,
	// apolloProvider: createProvider(),
	render: h => h(App)
}).$mount('#app')
