<template>
	<div>
		<i class="fa fa-bars" @click="show=true"></i>
		<div class="modal-bg" v-show="show" @click.self="show=false">
			<!-- 弹窗功能 -->
			<div class="modal-container">
				<div>
					<i @click="show=false" class="fa fa-close" style="color: #000;"></i>
				</div>
				<ul class="navbar-nav ml-auto">
					<li class="nav-item">
						<a @click="goUrl" class="nav-link" to="/invite"><i
								class="fas fa-user-plus"></i>&nbsp;{{$t('Invite')}}</a>
					</li>

					<li class="nav-item mobile">
						<router-link class="nav-link" to="/"><i class="fas fa-exchange-alt"></i>&nbsp;{{$t('trade1')}}
						</router-link>
					</li>
					<li class="nav-item mobile">
						<router-link class="nav-link" to="/pool"><i class="fas fa-dollar-sign"></i>&nbsp;{{$t('Mine')}}
						</router-link>
					</li>
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" href="#x" id="dropdown" data-toggle="dropdown"
							aria-haspopup="true" aria-expanded="false"><i class="fas fa-check"></i> <span
								class="va-middle"> {{$t('Audit')}}</span></a>
						<div class="dropdown-menu" aria-labelledby="dropdown">
							<a class="dropdown-item" target="_blank" href="/static/Mythx.pdf"><i class="fas fa-check"></i>
								<span>Mythx</span></a>
							<!-- <a class="dropdown-item" target="_blank" href="#"><i class="fas fa-check"></i>
								<span>審計鏈接</span></a> -->
						</div>
					</li>

					<li class="nav-item">
						<a class="nav-link" v-if="$i18n.locale=='en'" href="static/en.pdf"><i class="fas fa-user"></i>&nbsp;{{$t('about')}}
						</a>
						
						<a class="nav-link" v-if="$i18n.locale=='tc'" href="static/tc.pdf"><i class="fas fa-user"></i>&nbsp;{{$t('about')}}
						</a>
					</li>
					<li class="nav-item dropdown">
						<a class="nav-link dropdown-toggle" href="#x" id="dropdown" data-toggle="dropdown"
							aria-haspopup="true" aria-expanded="false"><i class="fas fa-link"></i> <span
								class="va-middle"> {{$t('Contact')}}</span></a>
						<div class="dropdown-menu" aria-labelledby="dropdown">

							<a class="dropdown-item" target="_blank" href="https://twitter.com/tigertoken2022"><i
									class="fa fa-twitter"></i>
								<span>{{$t('Twitter')}}</span></a>
						</div>
					</li>
					

				</ul>
				<ul class="navbar-button p-0 m-0 ml-30">
					<li class="nav-item">
						<template v-if="!$store.state.connected">
							<a class="btn btn-sm btn-primary-gradient"><span id="walletconnect"
									@click="onConnect">Connect Wallet</span></a>
						</template>
						<template v-if="$store.state.connected">
							<a class=" btn-primary-gradient">{{ $store.state.address | eth_address }}</a>
						</template>

					</li>
				</ul>

			</div>
		</div>
	</div>
</template>

<script>
	import Web3 from "web3";
	import {
		ethers
	} from 'ethers';
	// var vConsole = new VConsole(); 
	const Web3Modal = window.Web3Modal.default;
	const WalletConnectProvider = window.WalletConnectProvider.default;
	let web3Modal
	let provider;
	import contract_address from '@/common/config/contractAddress.js';
	import MiningPool from '@/common/config/MiningPool.json';
	import Token from '@/common/config/ERC20.json';
	let MiningPoolContract;
	import * as state from '@/store/contract.js'

	import {
		useContract
	} from '@/common/api/core.js'
	export default {
		props: {
			// show: {
			// 	type: Boolean,
			// 	default: true
			// }
		},
		data() {
			return {
				// PUMPAddress: PUMPAddress,
				// USDTaddress: contract_address.USDT,
				web3Modal: '',
				// payoutDialogVisible: false,
				// stakeDialogVisible: false,
				// cancleDialogVisible: false,
				invitecode: '', //邀请编码
				regDialogVisible: false, //是否显示绑定界面
				regDialogLoading: false,
				show: false,
			};
		},
		methods: {
			goUrl(){ //邀请链接跳转
				this.$router.push({path:'/invite'});
				this.show=false;
			},
			getUrlParam(key) {
				var href = window.location.hash;
				// console.log('路由', window.location.hash);

				// console.log(111111111111,href);
				var url = href.split("?");
				if (url.length <= 1) {
					return "";
				}
				// console.log('7777777',url);
				var params = url[1].split("&");
				for (var i = 0; i < params.length; i++) {
					var param = params[i].split("=");

					if (key == param[0]) {
						return param[1];
					}
				}
			},
			later() { //之后绑定，注销邀请码
				localStorage.removeItem("invitecode");
				this.regDialogVisible = false;
			},
			//绑定关系
			async bindReferrer() {
				let code = this.getUrlParam('code');
				// console.log('aaaaaaaa', code);
				if (code) {
					this.invitecode = code;
					localStorage.setItem('invitecode', code);
				} else {
					this.invitecode = localStorage.getItem('invitecode');
				}
				let address = this.$store.state.address;
				// let count = await MiningPoolContract.referreeCount(address);
				// //判断邀请链接是否具备邀请资格
				// if (this.invitecode) {
				// 	let is_invite = await MiningPoolContract.canBeReferer(this.invitecode);
				// 	if (!is_invite) {
				// 		this.$message.error('该账户不具备邀请资格');
				// 		return false;
				// 	}
				// }
				if (address && address != this.invitecode && this.invitecode) {
					MiningPoolContract.hasReferer(address).then(res => {
						console.log(res);
						if (!res) {
							this.regDialogVisible = true;
						}
					})
				}
			},
			//绑定邀请关系
			async setReferrer() {
				this.regDialogLoading = true; //显示状态 
				let trade = await MiningPoolContract.setReferer(this.invitecode);
				this.$message.success('请等待链上确认');
				this.regDialogVisible = false;
				this.regDialogLoading = false;
			},
			//绑定邀请关系
			// bind_relate
			lang(l) { //切换语言
				this.$i18n.locale = l;
			},

			handleClose() { //关闭邀请界面
				this.regDialogVisible = true;
			},
			async onConnect() {
				//链接钱包
				provider = await this.web3Modal.connect();

				state.contract.initializedContracts = true; //标记钱包已经链接
				//监听区块高度 
				const web3 = new Web3(provider);
				const accounts = await web3.eth.getAccounts();
				const address = accounts[0];
				localStorage.setItem('address', address);

				state.contract.default_account = address;
				await this.$store.commit('setState', {
					web3,
					provider,
					connected: true,
					address
				});
				let provider2 = new ethers.providers.Web3Provider(provider);
				const wallet = provider2.getSigner();
				MiningPoolContract = useContract('Refer');
				this.bindReferrer(); //绑定关系 
			},
		}
	}
</script>

<style lang="scss" scoped>
	.fa {
		color: #fff;
		font-size: 19px;

	}

	.fa-close {
		font-size: 30px;
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.modal-bg {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .5);
		z-index: 10;
	}

	.modal-container {
		display: block;
		position: absolute;
		z-index: 1003;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: #ffffff;
		padding: 50px 30px;
		width: 230px;
	}
</style>
