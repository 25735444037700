import Vue from 'vue'
import store from '@/store'
import {ethers} from 'ethers';
import {
		contract
	} from '@/store/contract.js'
// 大数处理 
const install = function(Vue) {
	//转化为大写
	Vue.filter('uppercase', function(value) {
		if (!value) {
			return ""
		}
		value = value.toString();
		return value.toUpperCase();
	});
	
	Vue.filter('filtersUnits', function(num) {
		 if (!num) return 0;
		 //将wei的数量格式化表示单位数量的十进制字符串
		 return ethers.utils.formatUnits(ethers.BigNumber.from(num));
	})
	 
	//地址简写
	Vue.filter('eth_address', function(value) {
		var len = value.length;
		var x = value.substring(10, len - 9);
		var values = value.replace(x, '****');
		return values;
	}) 
	//保留小数
	Vue.filter("toFloor", (number, scale = 4) => {
		if (new Number(number) == 0) {
			return 0;
		}
		var __str = number + "";
		if (__str.indexOf('e') > -1 || __str.indexOf('E') > -1) {
			var __num = new Number(number).toFixed(scale + 1);
			__str = __num + "";
			return __str.substring(0, __str.length - 1);
		} else if (__str.indexOf(".") > -1) {
			if (scale == 0) {
				return __str.substring(0, __str.indexOf("."));
			}
			return __str.substring(0, __str.indexOf(".") + scale + 1);
		} else {
			return __str;
		}
	}); 
	
	Vue.mixin({
		created() { //初始化地址
			this.$watch(() => contract.default_account, val => {
				if (val) {
					// console.log(val);
					this.mounted()
				}
			}, {
				immediate: true,
			})
		}, 
		methods: {
			mounted() { //激活状态
	
			}
		}
	})
};
/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
	install(window.Vue);
}
export default {
	install
}
