//多语言
import VueI18n from 'vue-i18n' 
import Vue from 'vue';
Vue.use(VueI18n) 
export default new VueI18n({
	locale: 'en', // 定义默认语言为中文  
	messages: {
		// 'zh': require('@/i18n/locale/zh.json'),
		'en': require('@/i18n/locale/en.json'),
		'tc': require('@/i18n/locale/tc.json')
	}
});